<template>
  <v-card>
    <v-card-title>
      <DatePickerInput
        prepend-inner-icon="mdi-calendar-range"
        className="shrink mr-3"
        :range="range"
        @input="setDates"
        readonly
        v-model="dateRange"
      />
      <FileUploadModal
        @uploadedFile="upload($event)"
        title="Ręczne ładowanie raportu"
        formats=".xlsx"
        ref="modal"
        :pending="uploadInProgress"
      >
        <DatePickerInput v-model="reportDate" />
      </FileUploadModal>
      <v-spacer />
      <v-text-field
        append-icon="mdi-magnify"
        class="shrink"
        @blur="fetchItems"
        v-model="search"
        label="Wyszukaj"
      />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="$data.$_paginationMixin_options"
      item-key="id"
      :loading="$data.$_paginationMixin_fetchingItems"
      :search="search"
      :items-per-page="$data.$_paginationMixin_itemsPerPage"
      :page="1"
      :server-items-length="$data.$_paginationMixin_totalCount"
      :footer-props="{
        'items-per-page-options': $data.$_paginationMixin_rowsPerPageOptions,
      }"
    >
      <template v-slot:item.bindingCategory="{item}">
        {{ item.category.name }}
      </template>
      <template v-slot:item.countValueInWeek="{item}">
        {{ item.countValueInWeek }}
      </template>
      <template v-slot:item.increasePercentage="{item}">
        {{ `${item.increasePercentage.toFixed(2)}%` }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import dateFilter from '@/filters/date.filter'
import paginationMixin from '@/mixins/pagination.mixin'
import DatePickerInput from '@/components/UI/DatePickerInput'
import FileUploadModal from '@/components/Modals/FileUploadModal'
import getWeekByDay from '@/utils/getWeekByDay'
import alertPresets from '@/utils/reports/alertConfig'

import {mapState, mapActions} from 'vuex'

export default {
  mixins: [paginationMixin],
  components: {
    DatePickerInput,
    FileUploadModal,
  },
  name: 'SearchNode',
  data() {
    return {
      search: '',
      uploadInProgress: false,
      format: 'YYYY-MM-DD',
      dateRange: [],
      range: true,
      reportDate: new Date().toISOString(),
      headers: [
        {text: 'Słowo kluczowe', value: 'keyWord'},
        {text: 'Kategorie do licytacji', value: 'bindingCategory'},
        {text: 'Wyszukiwań', value: 'countValueInWeek'},
        {text: '%Δ', value: 'increasePercentage'},
      ],
    }
  },
  computed: {
    ...mapState({
      items: state => state.reports.reportHistory,
    }),
    searchOptions() {
      return {
        ...this.$data.$_paginationMixin_options,
        dateRange: this.dateRange,
      }
    },
  },
  watch: {
    searchOptions: {
      handler() {
        this.fetchItems()
      },
      deep: true,
    },
  },
  created() {
    this.setDates([new Date().toISOString().slice(0, 10)])
  },
  methods: {
    setDates(d) {
      this.dateRange = getWeekByDay(d)
    },
    ...mapActions(['getReportHistory', 'uploadReport', 'showNotification']),
    async fetchItems() {
      const {searchOptions, search} = this
      this.$data.$_paginationMixin_fetchingItems = true
      const {dateRange} = searchOptions
      const options = {
        dateFrom: dateRange[0],
        dateTo: dateRange[1],
      }
      const query = this.$_paginationMixin_createQuery(
        {
          ...this.$data.$_paginationMixin_options,
          search,
        },
        options
      )
      this.$data.$_paginationMixin_totalCount = await this.getReportHistory({
        report: 'SEARCH_NODE',
        params: {
          ...query,
        },
      })
      this.$data.$_paginationMixin_fetchingItems = false
    },
    async upload(formData) {
      try {
        this.uploadInProgress = true
        const date = dateFilter(this.reportDate).replaceAll('.', '-')
        await this.uploadReport({
          report: 'SEARCH_NODE',
          formData,
          params: {
            date,
          },
        })
        this.$swal(alertPresets.success)
        this.uploadInProgress = false
        this.$refs.modal.close()
      } catch (e) {
        const {response} = e
        this.showNotification({
          type: 'error',
          message: response.data,
        })
        this.uploadInProgress = false
      }
      this.$refs.modal.file = null
    },
  },
}
</script>

<style scoped></style>
